<template>
  <div>
    <base-loader v-if="isLoading" />
    <div v-else class="stack-order page">
      <h1 class="page-title">
        {{ $t('title') }}
      </h1>
      <transition name="fade" mode="out-in">
        <div class="stack-order__content">
          <div>
            <page-block :title="$t('server.label')" size="bigger">
              <main-card class="stack-order__card">
                <base-input
                  v-model="name"
                  type="text"
                  :name="$t('server.label')"
                  :required="true"
                  use-reactive-validation
                  :pattern="validName ? name : name.toUpperCase()"
                  :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
                  label="Сервер"
                  :hint="$t('server.hint')"
                  class="form__field--input"
                  @input="onChange"
                >
                </base-input>
              </main-card>
            </page-block>
            <page-block :title="$t('source')" size="bigger">
              <main-card class="stack-order__card">
                <div class="l-col resize-flavor__config">
                  <div v-for="opt in nav" :key="opt.k" class="resize-flavor__config">
                    <base-radio
                      v-model="flavor"
                      :value="opt.k"
                      theme="tile"
                      class="resize-flavor__config-item"
                    >
                      {{ opt.v }}
                    </base-radio>
                  </div>
                </div>
                <div v-if="flavor === '1'" class="l-col">
                  <base-select
                    v-model="image"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    :options="images"
                    placeholder="Выберите публичный образ"
                    size="medium"
                    :custom-label="nameImage"
                    field-label="Образ"
                    :hint="'Вы можете выбрать предустановленный образ'"
                    :required="true"
                    :preselect-first="true"
                    track-by="name"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChange"
                  >
                  </base-select>
                </div>
                <div v-if="flavor === '2'" class="l-col">
                  <base-select
                    v-if="imagesPrivate.length"
                    v-model="image"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    :options="imagesPrivate"
                    placeholder="Выберите приватный образ"
                    size="medium"
                    :hint="'Вы можете выбрать загруженный вами ранее образ <br /> обратите внимание, в вашем образе должны быть установлены: <br /> - guestAgent <br /> - cloudInit'"
                    :required="true"
                    field-label="Мой образ"
                    :custom-label="nameImage"
                    track-by="name"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChange"
                  >
                  </base-select>
                  <div v-else>
                    <p>У Вас нет ни одного загруженного образа</p>
                    Загрузить свой образ можно на странице
                    <plain-button :href="imageLink" target="_blanc" color="primary">
                      Образы
                    </plain-button>
                  </div>
                </div>
                <div v-if="flavor === '3'" class="l-col">
                  <base-select
                    v-model="image"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    :options="diskForInstance"
                    placeholder="Выберите диск"
                    field-label="Диск"
                    size="medium"
                    :required="true"
                    :custom-label="nameImage"
                    track-by="name"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChange"
                  >
                  </base-select>
                </div>
                <div v-if="flavor === '4'" class="l-col">
                  <base-select
                    v-model="image"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    :options="snapshots"
                    field-label="Снапшот"
                    :required="true"
                    placeholder="Выберите снапшот"
                    size="medium"
                    :custom-label="nameImage"
                    track-by="name"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChange"
                  >
                  </base-select>
                </div>
              </main-card>
            </page-block>
            <page-block :title="$t('server.flavor')" size="bigger">
              <main-card class="stack-order__card">
                <div v-if="isQuotaRam === 0 || isQuotaCores === 0" class="l-col quotas">
                  <label class="typo__label big-title" v-html="quotaResources"></label>
                </div>
                <div v-else class="section-header">
                  <div class="l-col section-header">
                    <component
                      :is="componentsByType[item.type]"
                      v-for="item in configurator"
                      :key="item.intname"
                      v-bind="item"
                      class="cloud-config-config__item"
                      @change="onChange(item, $event)"
                    />
                  </div>
                  <div v-if="localDisk" class="l-col">
                    <p>Внимание!</p>

                    <p>
                      Локальный диск - это самый быстрый тип диска. Но для него невозможно сделать
                      снапшот. Сервер с этим типом диска нельзя мигрировать по физическим машинам
                      внутри облака. При удалении сервера локальный диск тоже удаляется. Клон
                      сервера с таким типом диска нужно создавать через образ, сделанный из диска.
                    </p>
                  </div>
                  <div class="l-col">
                    <div class="l-col resize-flavor__config">
                      <div v-for="opt in navFlavor" :key="opt.k" class="resize-flavor__config">
                        <base-radio
                          v-model="conf"
                          :value="opt.k"
                          theme="tile"
                          class="resize-flavor__config-item"
                        >
                          {{ opt.v }}
                        </base-radio>
                      </div>
                    </div>
                  </div>
                  <div v-if="conf === '1'" class="l-col">
                    <base-select
                      v-model="baseFlavor"
                      select-label=""
                      :hide-selected="true"
                      :searchable="false"
                      placeholder="Выберите стандартную конфигурацию"
                      :options="
                        localDisk
                          ? image.size
                            ? baseFlavorsWithDiskFiltred
                            : baseFlavorsWithDisk
                          : baseFlavorsWithoutDisk
                      "
                      size="medium"
                      :required="true"
                      field-label="Выберите стандартную конфигурацию"
                      :custom-label="localDisk ? nameFlavorWithDisk : nameFlavorWithoutDisk"
                      track-by="name"
                      :preselect-first="true"
                      :allow-empty="false"
                      class="new-disk__services"
                      @input="onChange"
                    >
                      <!--                    :preselect-first="true"-->
                    </base-select>
                  </div>
                  <div v-if="conf === '2'" class="l-col">
                    <!--                    v-for="item in backupFormData"-->
                    <transition-group tag="div" name="bubble">
                      <slider-stack-block
                        v-for="item in flavorOther"
                        :key="item.name"
                        :period="item.period"
                        :period-key="item.periodKey"
                        :config="item.config"
                        :label="item.label"
                        :changable="item.changable || false"
                        :cost="item.cost"
                        :hint="item.hint"
                        class="section-base"
                        :class="{ small: item.propwidth && item.propwidth === 'yes' }"
                        @change="onChangeSlider(item, $event)"
                      />
                    </transition-group>
                  </div>
                </div>
              </main-card>
            </page-block>
            <page-block :title="$t('server.networkDisk')" size="bigger">
              <main-card class="stack-order__card">
                <div v-if="quotaDiskCount === 0 || quotaDisk < 5" class="l-col quotas">
                  <label class="typo__label big-title" v-html="networkDiskError"></label>
                </div>
                <div v-else class="section-header">
                  <div v-if="localDisk || ['3', '4'].includes(flavor)" class="l-col">
                    <component
                      :is="componentsByType[item.type]"
                      v-for="item in networkDisk"
                      :key="item.intname"
                      v-bind="item"
                      class="network-disk section-base"
                      @change="onChange(item, $event)"
                    />
                  </div>
                  <div v-if="isNetworkDisk" class="l-col">
                    <component
                      :is="componentsByType[item.type]"
                      v-for="item in isDeleteNetworkDisk"
                      :key="item.intname"
                      v-bind="item"
                      class="network-disk section-base"
                      @change="onChange(item, $event)"
                    />
                  </div>
                  <div
                    v-if="
                      hideNetworkDisk ||
                      (['3', '4'].includes(flavor) && isNetworkDisk) ||
                      viewTypeOfNetworkDisk
                    "
                    class="l-col"
                  >
                    <base-select
                      v-model="type"
                      select-label=""
                      :hide-selected="true"
                      :searchable="false"
                      :options="typeOfDisk"
                      :required="true"
                      :field-label="$t('server.typeOfDisk')"
                      placeholder="Выберите тип диска"
                      size="medium"
                      :custom-label="nameType"
                      track-by="name"
                      :allow-empty="false"
                      class="new-disk__services"
                      @input="onChange"
                    >
                    </base-select>
                  </div>
                  <div v-if="hideNetworkDisk" class="l-col">
                    <slider-stack-block
                      :changable="true"
                      :config="networkDiskConfigurator.config"
                      :period="networkDiskConfigurator.period"
                      :desc="networkDiskConfigurator.desc"
                      :period-key="networkDiskConfigurator.periodKey"
                      :label="networkDiskConfigurator.label"
                      :cost="networkDiskConfigurator.cost"
                      :hint="networkDiskConfigurator.hint"
                      :class="{
                        small:
                          networkDiskConfigurator.propwidth &&
                          networkDiskConfigurator.propwidth === 'yes',
                      }"
                      @change="onChangeSlider(networkDiskConfigurator, $event)"
                    />
                    <!--                    <div v-if="flavor !== '1'">-->
                    <div>
                      <!--                      <div v-if="isMaxSize" class="l-col">-->
                      <div class="l-col">
                        <br />
                        <label class="typo__label medium-title error-color"> Внимание! </label>
                        <br />
                        <label class="typo__label medium-title"> {{ $t('upSize') }}</label>
                        <br />
                        <label class="typo__label medium-title">{{ $t('upSize2') }}</label>
                      </div>
                      <!--                      <div v-if="isMaxSize" class="l-col">-->
                      <div class="l-col"></div>
                    </div>
                  </div>
                </div>
              </main-card>
            </page-block>
          </div>
          <page-block :title="$t('network')" size="bigger">
            <main-card class="stack-order__card">
              <!--          <div class="l-col">-->
              <!--              <label class="typo__label big-title">{{ $t('network') }}</label>-->
              <div class="l-col resize-flavor__config">
                <div v-for="opt in navNetwork" :key="opt.k" class="resize-flavor__config">
                  <base-radio
                    v-model="network"
                    :value="opt.k"
                    :disabled="opt.disabled"
                    theme="tile"
                    class="resize-flavor__config-item"
                  >
                    {{ opt.v }}
                  </base-radio>
                </div>
              </div>
              <!--          </div>-->
              <div v-if="network === '1'">
                <div class="l-col">
                  <base-select
                    v-model="formData.port.fixed_ips[0].subnet_id"
                    ref="subnetname"
                    id="subnetname"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    placeholder="Выберите подсеть"
                    :custom-label="subnetName"
                    :preselect-first="true"
                    :required="true"
                    :field-label="$t('server.subnet')"
                    :options="subnet"
                    size="medium"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChangeNetwork"
                  >
                  </base-select>
                </div>
                <!--                  v-if="formData.port.fixed_ips[0].subnet_id && value.length && network === '1'"-->
                <div
                  v-if="
                    formData.port.fixed_ips[0].subnet_id &&
                    value.length &&
                    network === '1' &&
                    !allFreePorts.length
                  "
                  class="l-col"
                >
                  <base-input
                    v-model="buferIp"
                    :label="$t('server.port')"
                    class="form__field--input"
                    use-reactive-validation
                    :required="true"
                    :pattern="value"
                    @input="onChangeNetwork"
                  >
                  </base-input>
                </div>
                <div
                  v-else-if="
                    formData.port.fixed_ips[0].subnet_id &&
                    // value.length &&
                    network === '1' &&
                    allFreePorts.length
                  "
                >
                  <!--                  class="l-col"-->
                  <div :class="formData.portId === 'Новый порт' ? 'el-col-12' : 'l-col'">
                    <base-select
                      v-model="formData.portId"
                      select-label=""
                      :hide-selected="true"
                      :searchable="false"
                      placeholder="Выберите порт"
                      :preselect-first="true"
                      :custom-label="portName"
                      :required="true"
                      :field-label="$t('server.portAviable')"
                      :options="allFreePortsId"
                      size="medium"
                      :allow-empty="false"
                      class="new-disk__services"
                      @change="onChangeNetwork"
                    >
                    </base-select>
                  </div>
                  <div v-if="formData.portId === 'Новый порт'" class="el-col-12">
                    <base-input
                      v-model="buferIp"
                      :label="$t('server.port')"
                      class="form__field--input"
                      use-reactive-validation
                      :required="true"
                      :pattern="value"
                      @input="onChangeNetwork"
                    >
                    </base-input>
                  </div>
                </div>
                <div
                  v-if="
                    formData.port.fixed_ips[0].subnet_id &&
                    value === '' &&
                    network === '1' &&
                    !allFreePorts.length
                  "
                  class="l-col"
                >
                  <label class="typo__label section-header medium-title error-color">{{
                    $t('label.error')
                  }}</label>
                </div>
              </div>
              <div v-if="network === '3'" class="l-col">
                <label class="typo__label medium-title">{{ $t('networkPrivate') }}</label>
                <div class="resize-row medium-text section-header">
                  <base-select
                    v-model="prefix"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    :options="prefixesPublic"
                    placeholder="Выберите размер подсети"
                    size="medium"
                    field-label="Префикс"
                    :hint="'Размер публичной подсети'"
                    :required="true"
                    :preselect-first="true"
                    :allow-empty="false"
                    class="new-disk__services"
                    @input="onChange"
                  ></base-select>
                </div>

                <p>
                  {{ `Стоимость публичной подсети /${prefix} составляет` }}
                  <b>{{ $n(publicNetworkCost, 'currency') }}</b> в час
                </p>
                <div v-if="subnetPublic.length > 0" class="typo__label medium-text">
                  <!--                <div v-if="networksPublic.length > 0" class="typo__label medium-text">-->
                  <!--                  {{ `Вы будете подключены к ${networksPublicName} сети` }}-->
                  <!--                  {{ `Вы будете подключены к  сети` }}-->
                </div>
              </div>
              <div v-if="network === '2'">
                <div v-if="quotaPrivateNetwork === 0" class="l-col quotas">
                  <label class="typo__label big-title" v-html="privateNetworkError"></label>
                </div>
                <div v-else-if="quotaPrivateSubnet === 0" class="l-col quotas">
                  <label class="typo__label big-title" v-html="privateSubnetError"></label>
                </div>
                <div v-else>
                  <div class="l-col">
                    <div v-if="newNetworkList.length > 1">
                      <base-select
                        v-model="newNetworkName"
                        select-label=""
                        :hide-selected="true"
                        :searchable="false"
                        :options="newNetworkList"
                        :required="true"
                        :field-label="$t('server.networkChange')"
                        placeholder="Выберите сеть"
                        size="medium"
                        :custom-label="nameNewNetwork"
                        :allow-empty="false"
                        class="new-disk__services"
                        @input="onChangeNetwork"
                      >
                      </base-select>
                    </div>
                    <div v-if="newNetworkName === 'new network' || newNetworkList.length === 1">
                      <base-input
                        v-model="newNetName"
                        use-reactive-validation
                        :label="$t('server.net')"
                        :required="true"
                        placeholder="Название новой сети"
                        class="form__field--input"
                        @input="onChangeNewNetworkName"
                      >
                      </base-input>
                    </div>
                  </div>
                  <div class="l-col">
                    <base-input
                      :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
                      use-reactive-validation
                      :pattern="patternIP"
                      :value="subnetNetwork"
                      placeholder="Введите подсеть в формате 192.168.0.0/24"
                      :required="true"
                      :label="$t('server.subnet')"
                      class="form__field--input"
                      @input="onChangeNewNetwork('subnetNetwork', $event)"
                    >
                    </base-input>
                  </div>
                  <div class="l-col">
                    <base-input
                      use-reactive-validation
                      :value="gateway ? gateway : computeGateway"
                      class="form__field--input"
                      :required="true"
                      :readonly="true"
                      :label="$t('server.gateway')"
                      :pattern="computeGateway"
                      @input="onChangeNewNetwork('gateway', $event)"
                    >
                    </base-input>
                  </div>
                  <div class="l-col dns">
                    <base-checkbox
                      :key="formData.dhcp.key"
                      :name="formData.dhcp.name"
                      :hint="hintDns"
                      :value="formData.dhcp.value"
                      :true-value="formData.dhcp.trueValue"
                      :false-value="formData.dhcp.falseValue"
                      class="security-access-limit__field"
                      :class="{
                        ['security-access-limit__field--full']: formData.dhcp.key === 'dhcp',
                      }"
                      @change="onCheck('dhcp', $event)"
                    >
                      {{ formData.dhcp.label }}
                    </base-checkbox>
                    <plain-button
                      v-if="defaultDnsNames !== dnsNames"
                      color="primary"
                      class="tariffs-table__prolong"
                      @click="getDnsDefault"
                    >
                      {{ $t('dns.restore') }}
                    </plain-button>
                  </div>
                  <div v-if="formData.dhcp.value === 'on'" class="l-col">
                    <base-input
                      v-model="dnsNames"
                      type="textarea"
                      :placeholder="$t('dns.placeholder')"
                      @input="onChangeNewNetwork('dns', $event)"
                    />
                  </div>
                </div>
              </div>
              <div v-if="network === '4'">
                <div v-if="quotaFloatingIP === 0" class="l-col quotas">
                  <label class="typo__label big-title" v-html="floatingIpError"></label>
                </div>
                <div v-else>
                  <div v-if="isSubnetWithRouter.length > 0" class="l-col">
                    <!--                  <label class="typo__label medium-title">{{ $t('label.type') }}</label>-->
                    <base-select
                      v-model="formData.port.fixed_ips[0].subnet_id"
                      select-label=""
                      :hide-selected="true"
                      :searchable="false"
                      :preselect-first="true"
                      placeholder="Выберите подсеть"
                      :custom-label="subnetName"
                      :options="isSubnetWithRouter"
                      size="medium"
                      :allow-empty="false"
                      class="new-disk__services"
                      @input="onChangeNetwork"
                    >
                      <!--                    :options="subnet"-->
                    </base-select>
                  </div>
                  <div class="l-col">
                    <p>
                      Плавающий IP-адрес позволяет сделать сервер общедоступным. Каждый сервер имеет
                      внутренний фиксированный IP-адрес, а также может иметь общедоступный
                      (публичный) или плавающий IP-адрес. Публичные IP-адреса позволяют направлять
                      входящий сетевой трафик на ваши созданные сервера, к которым привязываются эти
                      публичные адреса.
                    </p>
                  </div>
                  <div v-if="isSubnetWithRouter.length === 0" class="l-col">
                    <p>
                      Автоматически будут созданы приватная сеть default-network, роутер
                      default-router и 1 публичный адрес.
                    </p>
                    <p>
                      Стоимость 1-го публичного адреса составляет
                      <b>{{ $n(floatIpCost, 'currency') }}</b> в час
                    </p>
                    <p>Сервер будет подключен к 192.168.0.0/24 в сети default-network.</p>
                  </div>
                  <!--                  <div v-if="routers.length === 0" class="l-col">нет роутера</div>-->
                  <!--                  <div v-if="networksPrivate.length < 2" class="l-col">нет подсети</div>-->
                  <div
                    v-if="
                      formData.port.fixed_ips[0].subnet_id && value.length > 0 && network === '4'
                    "
                    class="l-col"
                  >
                    <!--        v-model="formData.port.fixed_ips[0].ip_address"        -->
                    <base-input
                      v-model="buferIp"
                      :label="$t('server.ip')"
                      class="form__field--input"
                      :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
                      use-reactive-validation
                      :pattern="value"
                      @input="onChangeNetwork"
                    >
                    </base-input>
                  </div>
                  <div
                    v-if="
                      formData.port.fixed_ips[0].subnet_id && value.length === 0 && network === '4'
                    "
                    class="l-col"
                  >
                    <label class="typo__label medium-title error-color">{{
                      $t('label.error')
                    }}</label>
                  </div>
                </div>
              </div>
            </main-card>
          </page-block>
          <page-block
            v-if="!(image.format === 'iso' && flavor === '2')"
            :title="$t('server.root')"
            size="bigger"
          >
            <main-card class="stack-order__card">
              <div v-if="!(image.format === 'iso' && flavor === '2')" class="section-header">
                <!--                <div class="l-col">-->
                <!--                  <label class="typo__label big-title">{{ $t('server.root') }}</label>-->
                <!--                </div>-->
                <div v-if="!(image.format === 'iso' && flavor === '2')" class="l-col">
                  <label class="typo__label medium-title">{{
                    image.os === 'windows' ? $t('server.admin') : $t('server.password')
                  }}</label>
                </div>
                <div class="l-col password">
                  <!--                  :value="generatePass"-->
                  <base-input v-model="root" :readonly="true" class="password" style="width: 11rem">
                  </base-input>
                  <plain-button
                    v-tooltip="{
                      content: 'скопировать пароль в буфер обмена',
                      placement: 'bottom-start',
                      container: 'body',
                    }"
                    icon="copy-gray"
                    size="medium"
                    color="dim"
                    tabindex="-1"
                    class="password__field"
                    @click="copyToClipboard()"
                  />
                </div>
              </div>
            </main-card>
          </page-block>
          <page-block :title="$t('server.ssh')" size="bigger">
            <main-card class="stack-order__card">
              <!--              <div class="l-col">-->
              <!--                <label class="typo__label big-title">{{ $t('server.ssh') }}</label>-->
              <!--              </div>-->
              <!--              <div>-->
              <base-select
                v-model="sshKey"
                :hide-selected="true"
                :searchable="false"
                select-label=""
                :options="sshKeyList"
                placeholder="Выберите SSH-ключ"
                field-label="Ключ"
                size="medium"
                :allow-empty="false"
                class="el-col-12"
                @input="onChangeSshKey"
              >
              </base-select>
              <label v-if="!sshKey" class="typo__label el-col-12">{{ $t('server.sshtext') }}</label>
              <!--              </div>-->
            </main-card>
          </page-block>
          <!--          </div>-->
          <!--            </main-card>-->
          <!--          </page-block>-->
        </div>
      </transition>
    </div>
    <div v-if="!isLoading" class="typo__stack-order page">
      <div class="stack-order__content">
        <transition name="fade" mode="out-in">
          <page-block :title="$t('additional')" size="bigger">
            <div class="l-flex-gutter-20">
              <div class="l-col-12_ml-8">
                <main-card class="stack-order__card">
                  <label class="typo__label big-title">{{ $t('warnings') }}</label>
                  <transition name="fade" mode="out-in">
                    <page-block v-if="configToCost">
                      <ul class="order-summary__list section-base">
                        <li class="order-summary__item">
                          <div class="order-summary__label standart-title">
                            <label>Наименование</label>
                          </div>
                          <div class="order-summary__value standatr-text">количество в час</div>
                          <div class="order-summary__price standatr-text">
                            {{ `Цена за ${periodLabel}` }}
                          </div>
                        </li>
                        <li
                          v-for="(item, i) in viewConfigCost"
                          :key="i"
                          class="order-summary__item"
                        >
                          <div class="order-summary__label standart-title">
                            {{ $t(`summaryLabels.${item.name}`) }}
                          </div>
                          <div class="order-summary__value standatr-text">
                            {{ item.count }}
                          </div>
                          <div class="order-summary__price standatr-text">
                            {{ $n(item.price * item.count * period, 'currency') }}
                          </div>
                        </li>
                      </ul>
                    </page-block>
                  </transition>
                  <template #footer>
                    <div class="stack-order__footer">
                      <div class="stack-order__price">
                        <span class="stack-order__sum bigger-heading">
                          <!--                          Стоимость сервера {{ $n(cost * period, 'currency') }} в -->
                          Стоимость сервера
                          {{ $n(getSumFromViewConfigCost(viewConfigCost) * period, 'currency') }} в
                        </span>
                        <base-select
                          :value="periodValue"
                          :options="priceByPeriod"
                          :searchable="false"
                          :allow-empty="false"
                          label="label"
                          :show-labels="false"
                          class="vps-order__period"
                          @input="onPeriodChange"
                        />
                      </div>
                      <div class="stack-order__actions">
                        <base-button
                          :loading="runServerCreate"
                          :disabled="isCalculating || !aviableCreateServer"
                          class="stack-order__btn"
                          @click="createNewServer"
                        >
                          {{ `${$t('pay')}` }}
                        </base-button>
                      </div>
                    </div>
                  </template>
                </main-card>
              </div>
            </div>
          </page-block>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import passfather from 'passfather';
import BaseSelect from '@/components/Select/BaseSelect';
import newSsh from '@/mixins/newSsh';
import showErrorModal from '@/mixins/showErrorModal';
import wizardPay from '@/mixins/billmgr/wizardPay';
import handleRedirect from '@/mixins/billing/handleRedirect';
import updateBalance from '@/mixins/billing/updateBalance';
import PaymentMethodConfigurator from '@/models/BillMgr/PaymentMethodConfigurator';

import BaseInput from '@/components/BaseInput/BaseInput.vue';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';
import storeMixin from '@/layouts/Stack/mixins/index';
import SliderStackBlock from '@/components/Configurator/components/SliderStackBlock.vue';
import networks from '@/layouts/Stack/mixins/networks';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import OrderStackSummary from '@/components/Configurator/OrderStackSummary';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';
import Vue from 'vue';
import Clipboard from 'v-clipboard';
import OpenStackApi from '@/services/api/OpenStackApi';
Vue.use(Clipboard);
export default {
  name: 'StackOrderDetails',
  components: {
    // ResizeFlavor,
    BaseInput,
    BaseRadio,
    CheckboxBlock,
    BaseCheckbox,
    SliderStackBlock,
    OrderStackSummary,
    // BaseResizeFlavorItem,
    BaseSelect,
  },
  mixins: [showErrorModal, newSsh, wizardPay, handleRedirect, updateBalance, networks, storeMixin],
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      all: [],
      backupFormData: {},
      backupNetworkDisk: {},
      baseFlavor: [],
      baseFlavorsWithDiskFiltred: [],
      buferIp: '',
      componentsByType: {
        checkbox: CheckboxBlock,
      },
      // backupNetworkDisk: [],
      conf: '1',
      configData: null,
      configToCost: {},
      configurator: [
        {
          label: 'Локальный диск',
          hint: 'Выбор этого типа диска, создаст виртуальную машину, с диском SSD NVMe на том же физическом сервере, что и сама виртуальная машина, при этом он будет загрузочным.',
          type: 'checkbox',
          name: 'actiovationflag',
          k: null,
          setvalues: '',
          config: {
            values: {
              on: true,
              off: false,
            },
            restrictclientchange: 'off',
            value: 'off',
            trueValue: 'on',
            falseValue: 'off',
            disabled: false,
          },
          periodKey: 'month',
          restrictclientchange: 'off',
          // _currentValue: 'on',
          // _sum: 0,
        },
      ],
      configuratorClass: PaymentMethodConfigurator,
      cost: 0,
      costDetails: null,
      costFlavor: 0,
      costFloatingIp: 0,
      costNetworkDisk: 0,
      costPublicNetwork: 0,
      currentFlavorId: '',
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
        // confirm: { patternMismatch: 'test' },
      },
      defaultDnsNames: '',
      disk: [],
      dnsNames: '',
      flavor: '1',
      floatIp: '',
      floatIpAddress: '',
      formData: {
        port: {
          network_id: '',
          fixed_ips: [
            {
              subnet_id: '',
              ip_address: '',
            },
          ],
        },
        dhcp: {
          value: 'on',
          key: 'dhcp',
          label: 'Включить DHCP',
          trueValue: 'on',
          falseValue: 'off',
        },
        checkbox: {
          value: 'on',
          key: 'subnet',
          label: 'Создать подсеть',
          trueValue: 'on',
          falseValue: 'off',
        },
        subnet: {
          name: '',
          cidr: '',
          network_id: '',
          dhcp: '',
          gateway: '',
        },
        portId: '',
      },
      gateway: '',
      image: [],
      imagePrivate: [],
      ipUsed: [],
      isCalculating: false,
      isDeleteNetworkDisk: [
        {
          label: 'Удалять сетевой диск вместе с инстансом',
          hint: 'При удалении сервера, сетевой диск удалится. Обратите внимание, эту настройку нельзя будет поменять после создания сервера',
          type: 'checkbox',
          name: 'actiovationflag',
          k: null,
          setvalues: '',
          config: {
            values: {
              on: true,
              off: false,
            },
            value: 'on',
            trueValue: 'on',
            falseValue: 'off',
          },
          periodKey: 'month',
        },
      ],
      isMaxSize: false,
      isOk: false,
      isSendingToBasket: false,
      isSendingToPay: false,
      lastIpSection: '',
      listIp: [],
      listNetwork: [],
      mask: '',
      maskNewNetwork: '',
      // subnetNetwork: '',
      name: '',
      nav: [
        {
          v: this.$t('nav.image'),
          k: '1',
        },
        {
          v: this.$t('nav.other'),
          k: '2',
        },
        {
          v: this.$t('nav.disk'),
          k: '3',
        },
        {
          v: this.$t('nav.snap'),
          k: '4',
        },
      ],
      navFlavor: [
        {
          v: this.$t('nav.base'),
          k: '1',
        },
        {
          v: this.$t('nav.free'),
          k: '2',
        },
      ],
      network: '2',
      networkDisk: [
        {
          label: 'Сетевой диск',
          hint: 'Это тип хранилища данных, который используется для хранения и доступа к данным между виртуальными машинами в инфраструктуре вашего облака. ',
          type: 'checkbox',
          name: 'actiovationflag',
          k: null,
          setvalues: '',
          config: {
            values: {
              on: true,
              off: false,
            },
            value: 'on',
            trueValue: 'on',
            falseValue: 'off',
          },
          periodKey: 'month',
          // _currentValue: 'on',
          // _sum: 0,
        },
      ],
      networkId: '',
      networkPrivateId: '',
      networkWithSubnet: [],
      network_id: '',
      newIp: [],
      newNetName: '',
      newNetworkName: [],
      patternSubnets: [],
      period: 1,
      port_id: '',
      prefix: '29',
      pullIp: [],
      reserveIp: [],
      root: '',
      runServerCreate: false,
      snapshot: [],
      sshKey: [],
      startFunc: 'payment.add',
      startParams: {},
      subnetAddr: '',
      subnetNetwork: '192.168.0.0/24',
      subnet_id: '',
      subtitle: '',
      timerCnt: 0,
      title: '',
      type: {},
      useIp: [],
      value: '',
    };
  },
  computed: {
    routers() {
      return this.$store.state.moduleStack.routers;
    },
    quotaLink() {
      return IS_PROD
        ? `${window.location.origin}/#/stack/${this.id}/quotas`
        : `http://localhost:8080/#/stack/${this.id}/quotas`;
    },
    quotaResources() {
      if (this.isQuotaCores === 0 && this.isQuotaRam === 0) {
        return `Вы достигли квоты по использованию ядер и оперативной памяти. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
      } else if (this.isQuotaRam === 0 && this.isQuotaCores !== 0) {
        return `Вы достигли квоты по использованию оперативной памяти. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
      } else {
        return `Вы достигли квоты по использованию ядер. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
      }
    },
    networkDiskError() {
      return `Вы достигли квоты по использованию сетевых дисков. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
    },
    floatingIpError() {
      return `Вы достигли квоты по использованию плавающих адресов. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
    },
    privateNetworkError() {
      return `Вы достигли квоты по использованию приватных сетей. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
    },
    privateSubnetError() {
      return `Вы достигли квоты по использованию приватных подсетей. Перейдите в раздел <strong><a href="${this.quotaLink}">Квоты</a></strong> для увеличения. `;
    },
    isQuotaCores() {
      if (
        this.$store.state.moduleStack.quotasServer &&
        this.$store.state.moduleStack.quotasServer.cores
      ) {
        return (
          this.$store.state.moduleStack.quotasServer.cores.limit -
          this.$store.state.moduleStack.quotasServer.cores.in_use
        );
      } else return 0;
    },
    isQuotaRam() {
      if (
        this.$store.state.moduleStack.quotasServer &&
        this.$store.state.moduleStack.quotasServer.ram
      ) {
        return (
          this.$store.state.moduleStack.quotasServer.ram.limit -
          this.$store.state.moduleStack.quotasServer.ram.in_use
        );
      } else return 0;
    },
    validName() {
      return this.name.length > 0 && this.name.length < 64;
    },
    // isMaxSize() {
    //   return this.backupNetworkDisk.networkDisk > 2048;
    // },
    periodLabel() {
      return this.priceByPeriod.find(i => i.value === this.period).label.toLowerCase();
    },

    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    viewConfigCost: {
      get() {
        return this.configToCost;
      },
      set(value) {
        //console.log(value);
        return (this.configToCost.network.value = this.backupNetworkDisk.networkDisk
          ? this.configToCost.network.value
          : this.configToCost.network.value);
      },
      // }
    },
    devFlavor() {
      return IS_PROD ? 'price' : 'full_price';
    },
    navNetwork() {
      const nav = [
        {
          v: this.$t('nav.newNetwork'),
          k: '2',
          disabled: false,
        },

        {
          v: this.$t('nav.newPublicIp'),
          k: '4',
          disabled: false,
        },
      ];
      if (this.networksPrivate.length || this.subnetPublic.length) {
        nav.push({
          v: this.$t('nav.network'),
          k: '1',
          disabled: false,
        });
      }
      if (this.quotaPublicNetwork > 0) {
        nav.push({
          v: this.$t('nav.newPublicNetwork'),
          k: '3',
          disabled: false,
        });
      }
      return nav.sort((a, b) => a.k - b.k);
    },
    imageLink() {
      return `${localStorage.getItem('urlHash') ? localStorage.getItem('urlHash') : '#/'}stack/${
        this.id
      }/image`;
    },
    isLoading() {
      return this.$store.state.moduleStack.serverRequest;
    },
    computeGateway() {
      const gate = `${this.getMaskNewNetwork(this.subnetNetwork)}${this.getLastIpSectionNewNetwork(
        this.subnetNetwork
      )}`;
      // //console.log(gate);
      return gate === '1'
        ? ''
        : `${this.getMaskNewNetwork(this.subnetNetwork)}${this.getLastIpSectionNewNetwork(
            this.subnetNetwork
          )}`;
    },
    patternIP() {
      const mask = `${this.maskNewNetwork}${this.lastIpSection}/${this.subnetAddr}`;
      return this.isIPv4(this.maskNewNetwork) &&
        this.lastIpSection === 0 &&
        !this.patternSubnets.includes(mask) &&
        this.subnetAddr > 23 &&
        this.subnetAddr < 30
        ? mask
        : 'null';
    },
    newNetworkList() {
      const list = [];
      list.push('new network');
      return list;
    },
    sshKeyList() {
      // //console.log('ssh');
      const list = this.$store.state.moduleStack.keypairs.map(x => x.keypair.name);
      // list.push('new_key');
      list.push(this.$t('new_key'));
      return list;
    },

    quotaDisk() {
      return this.$store.getters['moduleStack/aviableSpaseVolume'];
    },
    quotaRam() {
      return Math.floor(this.$store.getters['moduleStack/aviableRam'] / 1024);
    },
    quotaCpu() {
      return this.$store.getters['moduleStack/aviableCpu'];
    },
    viewTypeOfNetworkDisk() {
      return (this.flavor === '1' || this.flavor === '2') && this.isNetworkDisk;
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
    floatIpCost() {
      return this.getPrice ? this.getPrice.find(x => x.intname === 'floating_ip')[this.dev] : 0;
    },
    publicNetworkCost() {
      const getCostForPrefix = `network_${this.prefix}`;
      // //console.log(getCostForPrefix);
      return this.getPrice ? this.getPrice.find(x => x.intname === getCostForPrefix)[this.dev] : 0;
      // return this.getPrice ? this.getPrice.find(x => x.intname === 'network_29')[this.dev] : 0;
    },
    getFlavorPrice() {
      return Object.keys(this.$store.state.moduleStack.flavorPrice).length
        ? this.$store.state.moduleStack.flavorPrice
        : null;
    },
    typeOfDisk() {
      return this.$store.state.moduleStack.typesOfDisks;
    },
    defaultTypeOfDisk() {
      return this.typeOfDisk.find(x => x.name === 'SSD');
    },
    flavorOther() {
      const config = [
        {
          name: 'cpu',
          cost:
            this.getPrice && this.getPrice.length
              ? this.getPrice.find(x => x.intname === 'vcpus')[this.dev]
              : null,
          period: '-1000',
          measure: 'МиБ',
          intname: 'disc',
          // setvalues: 'final',
          label: 'CPU',
          // hint: 'Значение дополнения с учетом включенного в тариф лимита:<br/> - включено в тариф: <b>6144 МиБ</b><br/> - заказано дополнительно: <b>0 МиБ</b>   <br/> - максимум: <b>4096000 МиБ</b>',
          type: 'slider',
          // desc: 'МиБ (0.00 RUB за месяц за 1024 МиБ)',
          periodKey: 'hour',
          config: {
            value: this.backupFormData.cpu
              ? this.backupFormData.cpu > 8
                ? 8
                : this.backupFormData.cpu
              : 1,
            min: 1,
            max: 8 < this.quotaCpu ? 8 : this.quotaCpu,
            // max: 8,
            step: 1,
            limit: 1,
            measure: 'шт.',
            restrictclientchange: 'off',
            disabled: false,
          },
        },
        {
          name: 'ram',
          cost:
            this.getPrice && this.getPrice.length
              ? this.getPrice.find(x => x.intname === 'memory')[this.dev]
              : null,
          period: '-1000',
          measure: 'ГиБ',
          intname: 'disc',
          // setvalues: 'final',
          label: 'Оперативная память',
          // hint: 'Значение дополнения с учетом включенного в тариф лимита:<br/> - включено в тариф: <b>6144 МиБ</b><br/> - заказано дополнительно: <b>0 МиБ</b>   <br/> - максимум: <b>4096000 МиБ</b>',
          type: 'slider',
          // desc: 'ГиБ (0.00 RUB за месяц за 1024 МиБ)',
          periodKey: 'hour',
          config: {
            // value: this.value.ram / 1024 < 1 ? 1 : this.value.ram / 1024,
            value: this.backupFormData.ram
              ? this.backupFormData.ram > this.quotaRam
                ? this.quotaRam
                : this.backupFormData.ram
              : 1,
            min: 1,
            max: 96 < this.quotaRam ? 96 : this.quotaRam,
            step: 1,
            limit: 1,
            measure: 'ГиБ',
            restrictclientchange: 'off',
            disabled: false,
          },
        },
      ];
      const disk = {
        name: 'disk',
        cost:
          this.getPrice && this.getPrice.length
            ? this.getPrice.find(x => x.intname === 'root_gb')[this.dev]
            : null,
        period: '-1000',
        measure: 'ГиБ',
        intname: 'disc',
        changable: true,
        // setvalues: 'final',
        label: 'Локальный диск',
        // hint: 'Значение дополнения с учетом включенного в тариф лимита:<br/> - включено в тариф: <b>6144 МиБ</b><br/> - заказано дополнительно: <b>0 МиБ</b>   <br/> - максимум: <b>4096000 МиБ</b>',
        type: 'slider',
        // desc: 'ГиБ (0.00 RUB за месяц за 1024 МиБ)',
        periodKey: 'hour',
        config: {
          value: this.backupFormData.disk
            ? this.backupFormData.disk
            : this.image.size && this.image.size > 5
            ? this.image.size
            : 5,
          min: this.image.size && this.image.size > 5 ? this.image.size : 5,
          // max: this.quotaDisk,
          max: 1024,
          step: 1,
          limit: 1,
          measure: 'ГиБ',
          restrictclientchange: 'off',
          disabled: false,
        },
      };
      if (this.localDisk) config.push(disk);
      // this.initValue;
      return config;
    },
    networkDiskConfigurator() {
      // return [
      return {
        disabled: false,
        name: 'networkDisk',
        cost:
          !!this.type.name && this.getPrice && this.getPrice.length
            ? this.getPrice.find(x => x.intname === `volume_${this.type.name}`)[this.dev]
            : this.getPrice
            ? this.getPrice.find(x => x.intname === 'volume_HDD')[this.dev]
            : 0,
        period: '-1000',
        measure: 'ГиБ',
        intname: 'disc',
        // setvalues: 'final',
        label: 'Сетевой диск',
        // hint: 'Значение дополнения с учетом включенного в тариф лимита:<br/> - включено в тариф: <b>6144 МиБ</b><br/> - заказано дополнительно: <b>0 МиБ</b>   <br/> - максимум: <b>4096000 МиБ</b>',
        type: 'slider',
        desc: 'ГиБ (0.00 RUB за месяц за 1024 МиБ)',
        periodKey: 'hour',
        config: {
          value:
            // this.backupNetworkDisk.length > 0 && this.backupNetworkDisk[0].config.value
            this.backupNetworkDisk.networkDisk && this.backupNetworkDisk.networkDisk > 0
              ? this.backupNetworkDisk.networkDisk
              : !this.localDisk && this.image.size && this.image.size > 5
              ? this.image.size
              : this.backupNetworkDisk.networkDisk && this.backupNetworkDisk.networkDisk > 0
              ? // ? this.backupNetworkDisk.networkDisk
                this.backupNetworkDisk.networkDisk
              : 5,
          //  : this.backupNetworkDisk[0].config.value,
          min: this.image.size && this.image.size > 5 ? this.image.size : 5,
          max: this.flavor === '1' ? 2048 : this.quotaDisk,
          step: 1,
          limit: 1,
          measure: 'ГиБ',
          restrictclientchange: 'off',
        },
      };
      // ];
    },
    list() {
      return this.$store.state.moduleStack.moduleStackOrder.list;
    },
    newIpAddress() {
      return this.newIp.length ? this.newIp.map(x => `${this.mask}${x}`) : [];
    },
    loading() {
      return this.$store.state.moduleStack.moduleStackOrder.loading;
    },
    images() {
      return this.$store.getters['moduleStack/imagesSort']
        .filter(x => x.visibility === 'public' && x.tags[0] !== 'rescue')
        .map(x => {
          return {
            name: x.name,
            id: x.id,
            os: x.os_type,
            format: x.disk_format,
            size:
              x.min_disk > 0
                ? x.min_disk
                : x.virtual_size > x.size
                ? Math.ceil(x.virtual_size / 1024 ** 3)
                : Math.ceil(x.size / 1024 ** 3),
            func: 'imageRef',
          };
        });
    },
    baseFlavorsWithoutDisk() {
      const array = this.flavors
        // .filter(x => x['os-flavor-access:is_public'] === true)
        .filter(x => x.ram < this.quotaRam * 1024)
        .filter(x => x.disk === 0);
      if (!!this.getFlavorPrice)
        array.forEach(index => {
          if (
            this.getFlavorPrice &&
            Object.keys(this.getFlavorPrice) &&
            Object.keys(this.getFlavorPrice).filter(price => price === index.id)
          ) {
            // //console.log(index.id);
            // //console.log(this.getFlavorPrice[index.id]);
            const cost = this.getFlavorPrice[index.id]
              ? this.getFlavorPrice[index.id][this.devFlavor]
              : 0;
            // this.getFlavorPrice[index.id] && this.getFlavorPrice[index.id][this.devFlavor]
            //   ? this.getFlavorPrice[index.id][this.devFlavor] || 0
            //   : 10;
            Object.assign(index, { cost: cost });
          } /*else {
            const cost = 12;
            Object.assign(index, { cost: cost });
          }*/
        });
      return array;
    },
    baseFlavorsWithDisk() {
      const array = this.flavors
        // .filter(x => x['os-flavor-access:is_public'] === true)
        .filter(x => 0 < x.disk)
        .filter(x => x.ram < this.quotaRam * 1024);
      if (!!this.getFlavorPrice)
        array.forEach(index => {
          if (
            Object.keys(this.getFlavorPrice).length &&
            Object.keys(this.getFlavorPrice).filter(price => price === index.id)
          ) {
            const cost = this.getFlavorPrice[index.id]
              ? this.getFlavorPrice[index.id][this.devFlavor]
              : 0;
            // this.getFlavorPrice[index.id] && this.getFlavorPrice[index.id][this.devFlavor]
            //   ? this.getFlavorPrice[index.id][this.devFlavor] || 0
            //   : 10;
            Object.assign(index, { cost: cost });
          } /*else {
            const cost = 12;
            Object.assign(index, { cost: cost });
          }*/
        });
      return array;
    },
    diskForInstance() {
      return this.disks.filter(x => x.bootable === 'true').filter(x => x.status.code === 15);
    },
    snapshots() {
      return this.$store.state.moduleStack.snapshots;
    },
    localDisk() {
      return this.configurator[0].config.value === 'on';
    },
    isNetworkDisk() {
      return this.networkDisk[0].config.value === 'on';
    },
    hideNetworkDisk() {
      if (this.localDisk || ['3', '4'].includes(this.flavor)) {
        if (this.isNetworkDisk) {
          return true;
        } else return false;
      } else return true;
    },
    imagesPrivate() {
      return this.$store.state.moduleStack.privateImages
        .filter(i => i.status.code === 1)
        .map(x => {
          return {
            name: x.name,
            id: x.id,
            size: x.min_disk > 0 ? x.min_disk : Math.ceil(x.size / 1024 ** 3),
            func: 'imageRef',
            os: x.os_type,
            format: x.disk_format,
          };
        });
    },
    current() {
      return this.$store.getters['moduleStack/moduleStackOrder/current'] || this.currentItem;
    },
    configTextFields() {
      return this.configurator.filter(addon => addon.type === 'text');
    },
    configLabels() {
      return this.configurator.reduce((acc, item) => {
        acc[item.name] = item.label;
        return acc;
      }, {});
    },
    availablePorts() {
      const arr = [];
      const subnets = this.subnet;
      subnets.forEach(net => {
        arr.push(this.getAvailablePorts(net));
      });
      return arr;
    },
    priceByPeriod() {
      return [
        {
          label: this.$t('hour'),
          value: 1,
        },
        {
          label: this.$t('day'),
          value: 24,
        },
        {
          label: this.$t('month'),
          value: 24 * 30,
        },
      ];
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === +this.period);
    },
    balance() {
      return this.$store.getters['moduleProfile/balance'];
    },
    priceListId() {
      return this.current.id;
    },
    prefixesPublic() {
      return this.$store.state.moduleStack.prefix;
    },
    currentItem() {
      return this.list.find(i => i.id === +this.id);
    },
    fullCost() {
      return this.costDetails ? this.costDetails.main.full_cost : null;
    },
    discountPercent() {
      return this.costDetails ? parseInt(this.costDetails.main.percent) : null;
    },
    allocation_pools() {
      return this.networkId
        ? this.$store.state.moduleStack.subnets.find(x => x.id === this.networkId).allocation_pools
        : null;
    },
    quotaNetworkDisk() {
      return this.localDisk ? this.localDisk : this.quotaDiskCount > 0 && this.quotaDisk > 5;
    },
    quotaFloatingIP() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.floatingip
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.floatingip.limit -
          this.$store.state.moduleStack.quotasNetwork.floatingip.used
        );
      } else return 0;
    },
    quotaPrivateNetwork() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.network
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.network.limit -
          this.$store.state.moduleStack.quotasNetwork.network.used
        );
      } else return 0;
    },
    quotaPrivateSubnet() {
      if (
        this.$store.state.moduleStack.quotasNetwork &&
        this.$store.state.moduleStack.quotasNetwork.subnet
      ) {
        return (
          this.$store.state.moduleStack.quotasNetwork.subnet.limit -
          this.$store.state.moduleStack.quotasNetwork.subnet.used
        );
      } else return 0;
    },
    quotaPublicNetwork() {
      // //console.log(this.$store.state.moduleStack.quota_limits.public_network);
      // //console.log(this.networksPublic.length);
      // //console.log(this.networksPublic);
      if (
        this.$store.state.moduleStack.quota_limits &&
        this.$store.state.moduleStack.quota_limits.public_network
      ) {
        return (
          // this.$store.state.moduleStack.quota_limits.public_network - this.networksPublic.length
          this.$store.state.moduleStack.quota_limits.public_network - this.subnetPublic.length
        );
      } else return 0;
    },
    currentConf() {
      return (
        (this.conf === '1' && Object.keys(this.baseFlavor).length > 0) ||
        (this.conf === '2' && this.backupFormData.ram && this.backupFormData.cpu > 0)
      );
    },
    allFreePorts() {
      return this.$store.state.moduleStack.ports
        .filter(x => x.network_id === this.formData.port.network_id)
        .filter(x => !x.device_id);
    },
    allFreePortsId() {
      const ports = this.allFreePorts.length ? this.allFreePorts.map(x => x.id) : [];
      const toCreatePorts = this.availableCreatedPorts(this.formData.port.fixed_ips[0].subnet_id);
      // //console.log(this.availableCreatedPorts(this.formData.port.fixed_ips[0].subnet_id));
      if (ports.length && toCreatePorts > 0) ports.push('Новый порт');
      // return this.allFreePorts.length ? this.allFreePorts.map(x => x.id) : [];
      return this.allFreePorts.length ? ports : [];
    },
    currentNetwork() {
      // //console.log(this.network === '1', this.formData.portId, this.allFreePorts.length);
      return (
        (this.network === '1' &&
          this.value.length > 0 &&
          // this.portId === '' &&
          this.allFreePorts.length === 0) ||
        (this.network === '1' &&
          this.formData.portId &&
          this.allFreePorts &&
          this.allFreePorts.length) ||
        (this.network === '2' && (this.quotaPrivateNetwork || this.quotaPrivateSubnet) > 0) ||
        (this.network === '3' && this.quotaPublicNetwork > 0) ||
        (this.network === '4' && this.quotaFloatingIP > 0)
      );
    },
    aviableCreateServer() {
      return !!(
        (
          this.name &&
          this.validName &&
          Object.keys(this.image).length > 0 &&
          this.quotaNetworkDisk &&
          this.currentConf &&
          this.currentNetwork
        ) //&&
      );
    },
    hintDns() {
      return `Корректная работа сети гарантируется только при использовании наших DNS-серверов:<br />
${this.defaultDnsNames.split('\n')[0]}<br />
${this.defaultDnsNames.split('\n')[1]}<br />
`;
    },
    summaryCost() {
      let sum = 0;
      if (this.conf === '1' && this.baseFlavor && this.isNetworkDisk) {
        sum += this.baseFlavor.cost + this.costNetworkDisk;
      } else if (this.conf === '1' && this.baseFlavor && !this.isNetworkDisk) {
        sum += this.baseFlavor.cost;
      } else if (this.conf === '2' && !this.isNetworkDisk) {
        sum += this.flavorOther.map(x => x.cost * x.config.value).reduce((a, b) => a + b);
      } else if (this.conf === '2' && this.isNetworkDisk) {
        sum +=
          this.flavorOther.map(x => x.cost * x.config.value).reduce((a, b) => a + b) +
          this.costNetworkDisk;
      }
      // if (this.isNetworkDisk) {
      //   //console.log('!!!');
      //   sum += this.costNetworkDisk;
      //   sum +=
      // }
      return sum || 0;
    },
    generateRootPassword() {
      return this.root
        ? btoa(
            '#cloud-config\n' +
              'chpasswd:\n' +
              '  list:\n' +
              `    - root:${this.root}\n` +
              '  expire: false'
          )
        : null;
    },
    decode() {
      return atob(this.generateRootPassword);
    },

    ports() {
      return this.$store.state.moduleStack.ports
        .filter(x => x.network_id === this.formData.port.network_id)
        .map(x => x.fixed_ips)
        .flat()
        .filter(x => x.subnet_id === this.networkId)
        .map(x => x.ip_address);
    },
  },
  watch: {
    backupFormData(event) {
      Object.keys(event).forEach(x => {
        this.configToCost[x].count = this.backupFormData[x];
      });
    },
    'backupNetworkDisk.networkDisk': function (event) {
      // //console.log(event);
    },
    'baseFlavor.cost': function (event) {
      if (this.conf === '1') {
        if (event === undefined) {
          delete this.configToCost.cpu;
          delete this.configToCost.ram;
          if (this.configToCost.disk) delete this.configToCost.disk;
        } else {
          this.costFlavor = event;
          this.configToCost.cpu = {
            name: 'cpu',
            count: this.baseFlavor.vcpus,
            price: this.getPrice.find(x => x.intname === 'vcpus')[this.dev],
          };
          this.configToCost.ram = {
            name: 'ram',
            count: this.baseFlavor.ram / 1024,
            price: this.getPrice.find(x => x.intname === 'memory')[this.dev],
          };
          if (this.baseFlavor.disk > 0) {
            this.configToCost.disk = {
              name: 'local',
              count: this.baseFlavor.disk,
              price: this.getPrice.find(x => x.intname === 'root_gb')[this.dev],
            };
          }
        }
      } else {
        this.costFlavor = 0;

        this.configToCost.cpu.count = this.backupFormData.cpu;
        this.configToCost.ram.count = this.backupFormData.ram;
        if (this.backupFormData.disk && this.configToCost.disk) {
          this.configToCost.disk.count = this.backupFormData.disk;
        }
      }
    },
    'baseFlavor.id': function (event) {
      if (this.conf === '1') {
        if (event === undefined) {
          delete this.configToCost.cpu;
          delete this.configToCost.ram;
          if (this.configToCost.disk) delete this.configToCost.disk;
        } else {
          // this.costFlavor = event;
          this.costFlavor =
            this.baseFlavor.vcpus * this.getPrice.find(x => x.intname === 'vcpus')[this.dev] +
            (this.baseFlavor.ram / 1024) *
              this.getPrice.find(x => x.intname === 'memory')[this.dev] +
            this.baseFlavor.disk * this.getPrice.find(x => x.intname === 'root_gb')[this.dev];
          this.baseFlavor.cost = this.costFlavor;
          this.configToCost.cpu = {
            name: 'cpu',
            count: this.baseFlavor.vcpus,
            price: this.getPrice.find(x => x.intname === 'vcpus')[this.dev],
          };
          this.configToCost.ram = {
            name: 'ram',
            count: this.baseFlavor.ram / 1024,
            price: this.getPrice.find(x => x.intname === 'memory')[this.dev],
          };
          if (this.baseFlavor.disk > 0) {
            this.configToCost.disk = {
              name: 'local',
              count: this.baseFlavor.disk,
              price: this.getPrice.find(x => x.intname === 'root_gb')[this.dev],
            };
          }
        }
      }
    },
    // costNetworkDisk(event) {
    //   //console.log(event);
    //   if (event) {
    //     this.configToCost.network.price = this.networkDiskConfigurator.cost;
    //     this.configToCost.network.count = this.networkDiskConfigurator.config.value;
    //   }
    // },
    computeGateway: function (event) {
      // //console.log(event);
      this.gateway = '';
      return this.getLastIpSectionNewNetwork(event) === 2
        ? (this.gateway = this.computeGateway)
        : (this.gateway = 'не верный формат подсети');
    },
    conf: function (event) {
      if (event === '2') {
        this.baseFlavor = [];
        this.backupFormData.ram = this.baseFlavor.ram ? this.baseFlavor.ram / 1024 : 1;
        this.backupFormData.cpu = this.baseFlavor.vcpus ? this.baseFlavor.vcpus : 1;
        // this.backupFormData.disk = this.localDisk ? this.backupFormData.disk : this.baseFlavor.disk;
        this.backupFormData.disk = this.localDisk ? this.backupFormData.disk : 5;
        this.costFlavor = 0;
        this.configToCost.cpu = {
          name: 'cpu',
          count: this.baseFlavor.vcpus,
          price: this.getPrice.find(x => x.intname === 'vcpus')[this.dev],
        };
        this.configToCost.ram = {
          name: 'ram',
          count: this.baseFlavor.ram / 1024,
          price: this.getPrice.find(x => x.intname === 'memory')[this.dev],
        };
        if (this.localDisk) {
          this.configToCost.disk = {
            name: 'local',
            count: this.baseFlavor.disk,
            price: this.getPrice.find(x => x.intname === 'root_gb')[this.dev],
          };
        }
        if (this.configToCost.cpu) this.configToCost.cpu.count = this.backupFormData.cpu;
        if (this.configToCost.ram) this.configToCost.ram.count = this.backupFormData.ram;
        // //console.log(this.configToCost.disk);
        if (
          this.localDisk &&
          this.configToCost.disk &&
          Object.keys(this.configToCost.disk).length > 0
        ) {
          this.configToCost.disk.count = this.backupFormData.disk;
        } else if (
          this.localDisk &&
          this.configToCost.disk &&
          Object.keys(this.configToCost.disk).length === 0
        ) {
          this.configToCost.disk = {
            name: 'local',
            count: this.backupFormData.disk,
            price: this.getPrice.find(x => x.intname === 'root_gb')[this.dev],
          };
        }
      } else this.costFlavor = this.baseFlavor.cost || 0;
    },
    cost: {
      handler: function (event) {
        //console.log('eventCost', event);
      },
    },
    costNetworkDisk(newVal, oldVal) {
      // //console.log(newVal, oldVal);
      this.isMaxSize = this.networkDiskConfigurator.config.value > 2048;
      this.cost += newVal;
      this.cost -= oldVal;
      //console.log('costNetworkDisk', this.cost);
      if (newVal !== oldVal) {
        this.configToCost.network.price = this.networkDiskConfigurator.cost;
        this.configToCost.network.count = this.networkDiskConfigurator.config.value;
      }
    },
    currentFlavorId() {
      if (this.conf === '1') {
        this.currentFlavorId = this.baseFlavor ? this.baseFlavor.id : '';
      }
    },
    flavor(newVal, oldVal) {
      if (newVal === '3' || newVal === '4') {
        this.configurator[0].config.value = 'off';
        this.networkDisk[0].config.value = 'off';
        this.configurator[0].config.disabled = true;
      } else {
        if (newVal === '1') {
          this.backupNetworkDisk.networkDisk =
            this.backupNetworkDisk.networkDisk > 2048 ? 2048 : this.backupNetworkDisk.networkDisk;
        }
        this.isMaxSize = this.networkDiskConfigurator.config.value > 2048;
        this.configurator[0].config.disabled = false;
        // this.networkDisk[0].config.value = 'on';
      }
      if (newVal !== oldVal) {
        this.image = [];
      }
    },
    getPrice(event) {
      event.forEach(stat => {
        if (stat.intname === 'floating_ip') {
          this.costFloatingIp = stat[this.dev];
        }
        if (stat.intname === 'network_29') {
          this.costPublicNetwork = stat[this.dev];
        }
      });
    },
    'image.size': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.baseFlavor = [];
        this.backupNetworkDisk.networkDisk = newVal > 5 ? newVal : 5;
        this.baseFlavorsWithDiskFiltred = this.baseFlavorsWithDisk.filter(x => newVal < x.disk);
      }
    },
    isNetworkDisk(event) {
      if (event === false) {
        delete this.configToCost.network;
      } else {
        this.configToCost.network = {
          name: 'network',
          count: this.networkDiskConfigurator.config.value,
          price: this.networkDiskConfigurator.cost,
        };
      }
    },
    listIp: function (event) {
      this.mask = '';
      this.value = '';
      this.newIp = [];
      if (event.length) {
        // //console.log(event);
        this.all = this.getAllPorts(
          this.getLastIpSection(this.allocation_pools[0].start),
          this.getLastIpSection(this.allocation_pools[0].end)
        );

        this.useIp = this.ports.map(x => this.getLastIpSection(x));
        this.newIp = this.all.filter(x => !this.useIp.includes(x));
        this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
        const ip = this.newIp.length > 0 ? this.newIp[0] : null;
        this.mask = this.getMask(this.allocation_pools[0].end);
        if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
      } else {
        this.pullIp = [
          this.getLastIpSection(this.allocation_pools[0].start),
          this.getLastIpSection(this.allocation_pools[0].end),
        ];
        this.all = [];
        this.allocation_pools.forEach(x => {
          this.all.push(
            this.getAllPorts(this.getLastIpSection(x.start), this.getLastIpSection(x.end))
          );
        });
        this.all = this.all.flat();
        this.newIp = Array.from(new Set(this.all.flat()));
        this.pullIp.forEach((x, i) => {
          if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
            this.newIp.push(this.pullIp[i]);
          }
        });
        const ip = this.allocation_pools[0].start;
        this.mask = this.getMask(ip);
        if (ip && this.newIp[0]) this.value = this.mask + this.newIp[0];
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        return; //console.log('список пуст!');
      }
    },
    localDisk(newVal, oldVal) {
      // //console.log(newVal, oldVal);
      if (newVal !== oldVal && newVal === true) {
        // //console.log('on');
        this.configToCost.disk = {
          name: 'local',
          count: this.backupFormData.disk ? this.backupFormData.disk : 5,
          price: this.getPrice.find(x => x.intname === 'root_gb')[this.dev],
        };
        this.backupFormData.disk = this.image.size && this.image.size > 5 ? this.image.size : 5;
        this.baseFlavor = [];
        if (!this.isNetworkDisk && this.quotaDiskCount > 0 && this.quotaDisk > 5) {
          // //console.log('on networkDisk');
          this.networkDisk[0].config.value = 'on';
        }
      } else if (newVal !== oldVal && newVal === false) {
        // //console.log('off');
        this.configToCost.network = {
          name: 'network',
          // count: this.networkDiskConfigurator[0].config.value,
          count: this.networkDiskConfigurator.config.value,
          // price: this.networkDiskConfigurator[0].cost,
          price: this.networkDiskConfigurator.cost,
        };
        delete this.backupFormData.disk;
        delete this.configToCost.disk;
      } else {
        // //console.log('else');
      }
    },
    network: function (newVal, oldVal) {
      if (newVal === '3' && oldVal !== '4') {
        // //console.log('3<-');
        // this.cost += this.getPrice.find(x => x.intname === 'network_29')[this.dev];
        this.cost += this.getPrice.find(x => x.intname === `network_${this.prefix}`)[this.dev];
        //console.log('network', this.cost);
        this.configToCost.public = {
          name: 'public',
          count: 1,
          price: this.getPrice.find(x => x.intname === `network_${this.prefix}`)[this.dev],
          // price: this.getPrice.find(x => x.intname === 'network_29')[this.dev],
        };
      } else if (newVal !== '4' && oldVal === '3') {
        // //console.log('<-3');
        // this.cost -= this.getPrice.find(x => x.intname === 'network_29')[this.dev];
        this.cost -= this.getPrice.find(x => x.intname === `network_${this.prefix}`)[this.dev];
        //console.log('network', this.cost);
        delete this.configToCost.public;
      } else if (newVal === '4' && oldVal !== '3') {
        // //console.log('4<-');
        this.cost += this.getPrice.find(x => x.intname === 'floating_ip')[this.dev];
        //console.log('network', this.cost);
        this.configToCost.float = {
          name: 'float',
          count: 1,
          price: this.getPrice.find(x => x.intname === 'floating_ip')[this.dev],
        };
      } else if (newVal !== '3' && oldVal === '4') {
        // //console.log('<-4');
        this.cost -= this.getPrice.find(x => x.intname === 'floating_ip')[this.dev];
        //console.log('network', this.cost);
        delete this.configToCost.float;
      } else if (newVal === '3' && oldVal === '4') {
        this.cost +=
          // this.getPrice.find(x => x.intname === 'network_29')[this.dev] -
          this.getPrice.find(x => x.intname === `network_${this.prefix}`)[this.dev] -
          this.getPrice.find(x => x.intname === 'floating_ip')[this.dev];
        //console.log('network', this.cost);
        this.configToCost.public = {
          name: 'public',
          count: 1,
          // price: this.getPrice.find(x => x.intname === 'network_29')[this.dev],
          price: this.getPrice.find(x => x.intname === `network_${this.prefix}`)[this.dev],
        };
        delete this.configToCost.float;
      } else if (newVal === '4' && oldVal === '3') {
        // //console.log('4<-3');
        this.cost +=
          this.getPrice.find(x => x.intname === 'floating_ip')[this.dev] -
          this.getPrice.find(x => x.intname === 'network_29')[this.dev];
        //console.log('network', this.cost);
        this.configToCost.float = {
          name: 'float',
          count: 1,
          price: this.getPrice.find(x => x.intname === 'floating_ip')[this.dev],
        };
        delete this.configToCost.public;
      }
      if (newVal !== oldVal && newVal === '3') {
        // //console.log(newVal);
      } else if (newVal !== oldVal && newVal === '2') {
        // //console.log(newVal);
      }
    },
    newNetworkName(event) {
      if (event !== 'new network') {
        const sub = this.$store.state.moduleStack.networks.find(net => net.id === event).subnets;
        const cidr = [];
        sub.forEach(sub => {
          cidr.push(this.$store.state.moduleStack.subnets.find(x => x.id === sub).cidr);
        });
        this.patternSubnets = cidr.sort();
        const mask = this.patternSubnets.at(-1) ? this.patternSubnets.at(-1).split('/')[0] : null;
        let first = +mask.split('.')[0];
        let second = +mask.split('.')[1];
        let third = +mask.split('.')[2];
        const forty = +mask.split('.')[3];
        if (third + 1 < 255) {
          third += 1;
        } else if (first + 1 < 255) {
          first += 1;
        }
        this.subnetNetwork = first + '.' + second + '.' + third + '.' + forty + '/24';

        this.newNetName = '';
      } else {
        this.patternSubnets = [];
        this.subnetNetwork = '192.168.0.0/24';
      }
    },
    portId(event) {
      // //console.log(event);
    },
    port_id: function (newValue, oldValue) {
      // //console.log('watch', 'new:', newValue, 'old:', oldValue);
    },
    prefix: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.cost += this.getPrice.find(x => x.intname === `network_${newVal}`)[this.dev];
        this.cost -= this.getPrice.find(x => x.intname === `network_${oldVal}`)[this.dev];
        //console.log('prefix', this.cost);
        this.configToCost.public = {
          name: 'public',
          count: 1,
          price: this.getPrice.find(x => x.intname === `network_${newVal}`)[this.dev],
        };
      }
    },
    sshKey: function (event) {
      // if (event === 'new_key') {
      // //console.log(event);
      if (event === this.$t('new_key')) {
        this.sshKey = [];
        this.newSsh();
      }
    },
    sshKeyList: function (newVal, oldVal) {
      // //console.log(newVal, oldVal);
      if (newVal !== oldVal) {
        let difference = newVal.filter(num => !oldVal.includes(num))[0];
        // //console.log(difference);
        this.sshKey = difference ? difference : this.sshKey;
      }
    },
    summaryCost: {
      handler: function (newValue, oldValue) {
        // this.cost += newValue - oldValue;
        this.cost = newValue;
        // console.log(newValue, oldValue);
        if (this.configToCost.network)
          this.configToCost.network.count = this.networkDiskConfigurator.config.value;
      },
      immediate: true,
    },
    'type.name': function () {
      this.costNetworkDisk =
        this.networkDiskConfigurator.config.value * this.networkDiskConfigurator.cost;
      this.configToCost.network.price = this.networkDiskConfigurator.cost;
      this.configToCost.network.count = this.networkDiskConfigurator.config.value;
    },
    value: function (event) {
      const ip = this.getLastIpSection(event);
      if (this.newIp.includes(ip)) {
        this.formData.port.fixed_ips[0].ip_address = event;
        return this.$emit('change', {
          // port: this.formData.port,
          port: {
            network_id: this.formData.port.network_id,
            fixed_ips: [
              {
                subnet_id: this.formData.port.fixed_ips[0].subnet_id.id,
                ip_address: this.formData.port.fixed_ips[0].ip_address,
                device_id: this.formData.port.fixed_ips[0].device_id,
              },
            ],
          },
        });
      } else {
        return this.$emit('notready');
      }
    },
  },
  beforeRouteLeave(_to, _from, next) {
    this.$store.dispatch('moduleStack/moduleStackOrder/reset');
    next();
  },
  mounted() {
    this.fetchServer();
    this.init();
    this.formData.subnet = {
      name: this.subnetNetwork,
      cidr: this.subnetNetwork,
      network_id: !this.newNetName ? this.newNetworkName : '',
      dhcp: this.formData.dhcp.value,
      dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
      gateway: this.computeGateway,
    };
  },
  beforeDestroy() {
    this.networkId = '';
  },
  methods: {
    getSumFromViewConfigCost(obj) {
      return Object.values(obj).reduce((a, b) => a + b.price * b.count, 0);
    },
    async createOtherFlavor(params) {
      return this.$store.dispatch('moduleStack/createOtherFlavor', params);
    },
    async createFlavor() {
      const disk = this.localDisk && this.backupFormData.disk ? this.backupFormData.disk : 0;
      const params = {
        flavor: {
          name: `uF-${this.backupFormData.cpu}-${this.backupFormData.ram * 1024}-${disk}-${
            Math.random() * 100000000000000000
          }`,
          ram: this.backupFormData.ram * 1024,
          disk: disk,
          vcpus: this.backupFormData.cpu,
          'os-flavor-access:is_public': false,
        },
      };
      await this.createOtherFlavor(params)
        .then(async data => {
          this.currentFlavorId = data.flavor.id;
        })
        .catch(e => {
          this.showError(e.data.forbidden.message);
        });
    },
    async createPortCurrentNetwork() {
      const params = {
        port: this.formData.port,
      };
      const port_id = await this.$store
        .dispatch('moduleStack/createPort', params)
        .then(async data => {
          return data.port.id;
        })
        .catch(e => {
          this.showError(e);
        });
      // //console.log('port_id', port_id);
      return port_id;
    },
    async createFloatIpNetwork() {
      const network = this.networks.find(x => x.is_default === true).id;
      // //console.log(network);
      const payload = {
        floatingip: {
          floating_network_id: network,
        },
      };

      // })
      const float = await this.$store
        .dispatch('moduleStack/createFloatIp', payload)
        .then(data => {
          this.floatIp = data.floatingip.id;
          this.floatIpAddress = data.floatingip.floating_ip_address;
          return this.floatIp;
        })
        .then(async () => {
          await this.createPortCurrentNetwork().then(data => {
            // //console.log(data);
            this.port_id = data;
          });
        })
        .then(async () => {
          setTimeout(async () => {
            const params = {
              port: this.port_id,
              id: this.floatIp,
            };

            await this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
              this.showError(e);
            });
          }, 1000);
        })
        .catch(e => {
          //console.log(e);
          this.showError(e);
        });
    },
    getStackPrice() {
      return this.$store.dispatch('moduleStack/getPrice', this.id).then(data => {
        // //console.log(data);
      });
    },
    async createSubnetOnly() {
      const params = {
        subnet: this.formData.subnet.name,
        id: this.formData.subnet.network_id,
        gateway: this.formData.subnet.gateway,
        dhcp: this.formData.dhcp.value,
        dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
      };
      if (this.formData.dhcp.value) params.dnsNames = this.$store.state.moduleStack.dnsDefault;

      const paramsPort = await this.$store.dispatch('moduleStack/createSubnet', params).catch(e => {
        this.showError(e);
      });

      // впихнуть сюда роутер

      return paramsPort;
    },
    getDnsDefault() {
      this.dnsNames = this.$store.state.moduleStack.dnsDefault.join('\n');
      this.defaultDnsNames = this.dnsNames;
    },
    async createSubnet() {
      const params = {
        subnet: this.formData.subnet.name,
        id: this.formData.subnet.network_id,
        gateway: this.formData.subnet.gateway,
        dhcp: this.formData.dhcp.value,
      };
      if (this.formData.dhcp.value) params.dnsNames = this.$store.state.moduleStack.dnsDefault;

      const paramsPort = await this.$store.dispatch('moduleStack/createSubnet', params).catch(e => {
        this.showError(e);
      });
      // //console.log(paramsPort);
      const payload = {
        port: {
          network_id: paramsPort.subnet.network_id,
          fixed_ips: [
            {
              subnet_id: paramsPort.subnet.id,
            },
          ],
        },
      };
      return await this.$store
        .dispatch('moduleStack/createPort', payload)
        .then(data => {
          // //console.log(data);
          return data.port.id;
        })
        .catch(e => {
          this.showError(e);
        });
      // return port_id;
    },
    copyToClipboard() {
      this.$clipboard(this.root);
      this.showSuccessModal('Пароль успешно скопирован в буфер обмена');
    },
    async newPublicNetwork() {
      this.isSending = true;
      return this.$store
        .dispatch('moduleStack/createPublicNetwork', { id: this.bmId, prefix: this.prefix })
        .then(async data => {
          //console.log(data);
          if (data && data.result === 'ok') {
            this.subnet_id = data.subnet_id;
            await this.$store
              .dispatch('moduleStack/fetchOpenStackRequest', 'subnets')
              .then(data => console.log(data));
            // setTimeout(
            //   async () =>
            //     await this.$store
            //       .dispatch('moduleStack/fetchOpenStackRequest', 'subnets')
            //       .then(data => //console.log(data)),
            //   2000
            // ); // сделать зацикленным запрос, пока не появятся заполненные поля
            //console.log(this.subnet_id);
            //console.log(this.$store.state.moduleStack.subnets);
            const portsParams = await this.$store.state.moduleStack.subnets.find(
              x => x.id === this.subnet_id
            );
            //console.log(portsParams);
            // const paramsPort = await this.$store
            //   .dispatch('moduleStack/fetchCurrentNetwork', this.network_id)
            //   .then(data => {
            //     return data;
            //   })
            //   .catch(e => {
            //     this.showError(e);
            //   });

            const params = {
              port: {
                network_id: portsParams.network_id,
                fixed_ips: [
                  {
                    subnet_id: portsParams.id,
                  },
                ],
              },
            };
            //console.log(params);
            const port_id = await this.$store
              .dispatch('moduleStack/createPort', params)
              .then(data => {
                // //console.log(data);
                this.formData.portId = data.port.id;
                return data.port.id;
              })
              .catch(e => {
                this.showError(e);
              });
            return port_id;
          }
        })
        .catch(e => this.showError(e))
        .finally(() => (this.isSending = false));
    },
    async addToPublicNetwork() {
      this.isSending = true;
      const net = this.$store.state.moduleStack.subnets.find(
        // const net = this.$store.state.moduleStack.networks.find(
        //   net => net.id === this.networksPublic[0]
        // );
        net => net.id === this.subnetPublic[0]
      );
      const params = {
        port: {
          network_id: net.id,
          fixed_ips: [
            {
              subnet_id: net.subnets[0],
            },
          ],
        },
      };
      const port_id = await this.$store
        .dispatch('moduleStack/createPort', params)
        .then(data => {
          // //console.log(data);
          return data.port.id;
        })
        .catch(e => {
          this.showError(e);
        });
      return port_id;
    },
    generatePass() {
      const pass = passfather({
        numbers: false,
        uppercase: false,
        lowercase: false,
        symbols: false, // Disable symbols
        ranges: [
          [
            [65, 72],
            [74, 78],
            [80, 90],
            [97, 107],
            [109, 122],
          ],
          [[50, 57]],
        ],
        length: 16,
      });
      this.root = pass;
      return pass;
    },
    getServerName() {
      return (this.name = `server-${Date.now()}`);
    },
    getNetworkName() {
      return (this.newNetName = `network-${Date.now()}`);
    },
    offNetworkDisk() {
      if (this.quotaDiskCount === 0 || this.quotaDisk < 5) {
        this.networkDisk[0].config.value = 'off';
        if (this.configToCost.network) delete this.configToCost.network;
      } else {
        this.costNetworkDisk =
          this.networkDiskConfigurator.config.value * this.networkDiskConfigurator.cost;
        this.configToCost.network = {
          name: 'network',
          count: this.networkDiskConfigurator.config.value,
          price: this.networkDiskConfigurator.cost,
        };
      }
    },
    init() {
      this.$emit('notready');
      this.generatePass();
      this.getServerName();
      this.getNetworkName();
      this.quotaPublicNetwork;
      this.offNetworkDisk();
      this.getDnsDefault();
      this.$store.dispatch('moduleStack/getQuotas', this.id);
      if (!this.getPrice) this.getStackPrice();
      this.backupNetworkDisk.networkDisk = this.networkDiskConfigurator.config.value;
      // //console.log(this.defaultTypeOfDisk);
      this.type = this.defaultTypeOfDisk;
      // if (this.subnetPublic.length || this.networksPrivate.length) {
      //   this.navNetwork.push({
      //     v: this.$t('nav.network'),
      //     k: '1',
      //     disabled: false,
      //   });
      //   //console.log('2319', this.navNetwork);
      //   this.navNetwork.sort((a, b) => a.k - b.k);
      // }
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    nameType({ name }) {
      return `${name}`;
    },
    nameNewNetwork(id) {
      if (this.networks.find(x => x.id === id)) {
        const name = this.networks.find(x => x.id === id).name;
        return `${name}`;
      } else return 'Новая сеть';
    },
    // api and logic methods
    fetchPricelist() {
      const params = {
        show_metadata: 'on',
        newface: 'on',
      };
      this.$store.dispatch('moduleStack/moduleStackOrder/fetchPricelist', params);
    },
    nameImage({ name, size }) {
      return `${name} (${size}ГБ)`;
    },
    nameFlavorWithDisk({ name, vcpus, ram, disk, cost }) {
      let viewCost = cost ? `(${cost.toFixed(2)}  ₽/ Час)` : '';
      return `${name} (ЦПУ:${vcpus} - Память:${ram / 1024}ГБ - Диск:${disk}ГБ) ` + viewCost;
    },
    nameFlavorWithoutDisk({ name, vcpus, ram, cost }) {
      let viewCost = cost ? `(${cost.toFixed(2)}  ₽/ Час)` : '';
      return `${name} (ЦПУ:${vcpus} - Память:${ram / 1024}ГБ) ` + viewCost;
    },
    getFormDataParams(payload = {}) {
      const formData = this.configData ? this.configData : this.current.info.model;
      if (
        this.configData &&
        this.configData.autoprolong &&
        this.configData.autoprolong !== 'null'
      ) {
        formData.autoprolong = this.period;
      }
      const params = {
        pricelist: this.current.id,
        ...formData,
        ...payload,
        period: +this.period,
      };
      return params;
    },
    fetchParams(item, period) {
      const params = {};
      if (item) params.id = item;
      if (period) params.period = +period;
      return this.$store.dispatch('moduleStack/moduleStackOrder/fetchParams', params);
    },
    createNetwork(payload) {
      return this.$store.dispatch('moduleStack/createNetwork', payload).catch(e => {
        this.showError(e);
      });
    },
    createRouterWithNetwork() {
      return this.$store.dispatch('moduleStack/createRouterWithNetwork', this.id).then(data => {
        // //console.log(data);
        if (data && data.result === 'ok') {
          this.formData.port.network_id = data.network.id;
          return data;
        }
        if (data && data.result === 'error') {
          // //console.log('-------------------------------------', data.message);
          // this.showError(data.message);
          return 'error';
        }
      });
      // .catch(e => {
      // });
    },
    deleteCurrentPort(port) {
      return this.$store.dispatch('moduleStack/deletePort', {
        port: port,
      });
    },
    async createServer() {
      if (this.conf === '2') {
        await this.createFlavor();
        // //console.log('создаем флавор');
      }
      // if (this.network === '3' && this.networksPublic.length === 0) {
      if (this.network === '3' && this.quotaPublicNetwork > 0) {
        return await this.newPublicNetwork()
          .then(data => {
            this.port_id = data;
            // //console.log('port_id', data);
          })
          .catch(e => {
            this.showError(e);
          });
        // //console.log('network 3 port id:');
        // } else if (this.network === '3' && this.networksPublic.length) {
      } else if (this.network === '3' && this.subnetPublic.length) {
        return await this.addToPublicNetwork()
          .then(data => {
            this.port_id = data;
            // //console.log('port_id', data);
          })
          .catch(e => {
            this.showError(e);
          });
        // //console.log('network 3 port id:');
      } else if (this.network === '1' && this.allFreePorts.length === 0) {
        await this.createPortCurrentNetwork()
          .then(data => {
            this.port_id = data;
            this.formData.portId = 'Новый порт';
            this.formData.portId = data;
            // //console.log('port_id', data);
          })
          .catch(e => {
            this.showError(e);
          });
        // //console.log('network 1', this.formData.port);
      } else if (
        this.network === '1' &&
        this.allFreePorts &&
        this.formData.portId === 'Новый порт'
      ) {
        await this.createPortCurrentNetwork()
          .then(data => {
            this.port_id = data;
            // //console.log('port_id', data);
          })
          .catch(e => {
            this.showError(e);
          });
        // //console.log('network 1', this.formData.port);
      } else if (this.network === '2') {
        if (this.newNetName) {
          await this.createNetwork({ name: this.newNetName })
            .then(async data => {
              await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
              this.formData.subnet.network_id = data.network.id;
              this.networkPrivateId = data.network.id;
            })
            .then(async () => {
              await this.createSubnet()
                .then(data => {
                  this.port_id = data;
                  this.formData.portId = data;
                })
                .catch(e => {
                  this.showError(e);
                });
            });
        } else
          await this.createSubnet()
            .then(data => {
              // //console.log('port_id', data);
              this.port_id = data;
              this.formData.portId = data;
            })
            .catch(e => {
              this.showError(e);
            });
        // //console.log('network 2');
        // network === 4
      } else if (this.network === '4') {
        if (this.isSubnetWithRouter.length === 0) {
          await this.createRouterWithNetwork()
            .then(async data => {
              //console.log(data);
              if (data && data.result !== 'error') {
                await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'subnets');
                const subnetId = this.$store.state.moduleStack.subnets.find(
                  x => x.network_id === data.network.id
                ).id;
                this.formData.port.network_id = data.network.id;
                this.formData.port.fixed_ips[0].subnet_id = subnetId;
                this.formData.port.fixed_ips[0].ip_address = '192.168.0.6';
              } else {
                await this.createNetwork({ name: 'default-network' })
                  .then(async data => {
                    // //console.log(data);
                    await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');
                    this.formData.port.network_id = data.network.id;
                    this.formData.subnet.network_id = data.network.id;
                    // this.networkPrivateId = data.network.id;
                  })
                  .then(async () => {
                    await this.createSubnetOnly()
                      .then(async data => {
                        this.formData.port.fixed_ips[0].subnet_id = data.subnet.id;
                        this.formData.port.fixed_ips[0].ip_address = '192.168.0.6';
                        const params = {
                          port: {
                            network_id: this.formData.port.network_id,
                            fixed_ips: [
                              {
                                subnet_id: data.subnet.id,
                                ip_address: '192.168.0.1',
                              },
                            ],
                          },
                        };
                        this.addNewPort(params)
                          .then(async data => {
                            await this.addRouterInterface({
                              port_id: data.port.id,
                              router_id: this.routers[0].id,
                            }).then(async data => {
                              if (data) {
                                // this.isProcessing = false;
                                // this.newFetch();
                              }
                            });
                          })
                          .catch(e => {
                            //console.log(e);
                            this.showError(e);
                          });
                        // this.port_id = data;
                        // this.formData.portId = data;
                      })
                      .catch(e => {
                        this.showError(e);
                      });
                  });
              }
            })
            .catch(e => {
              //console.log('!!!!!!!!!!!!!!!!!!!!!!!', e);
              this.showError(e);
            });
          // }
        }

        await this.createFloatIpNetwork()
          .then(() => {
            // //console.log(data);
            // //console.log(this.port_id, this.formData.portId);
            this.formData.portId = this.port_id;
            // this.port_id = data;
            // //console.log(data);
          })
          .catch(e => {
            console.error(e);
            this.formData.portId = '';
            this.showError(e);
          });
        await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'networks');

        const netForDelete = this.networksPrivate.filter(
          net => !this.networksWithRouter.includes(net)
        );
        if (netForDelete.length > 0) await this.deleteCurrentNetwork(netForDelete[0]);
      }
    },
    deleteCurrentNetwork(net) {
      return this.$store
        .dispatch('moduleStack/deleteNetwork', {
          net: net,
        })
        .catch(e => e);
    },
    addRouterInterface(params = {}) {
      let url = `/network/v2.0/routers/${params.router_id}/add_router_interface`;
      return new Promise((resolve, reject) => {
        // setTimeout(() => {
        const payload = {
          port_id: params.port_id,
        };
        OpenStackApi.put(url, JSON.stringify(payload))
          .then(({ data }) => {
            resolve(data);
          })
          .catch(e => reject(e.response));
        // }, 1000);
      });
    },
    addNewPort(payload) {
      return this.$store.dispatch('moduleStack/createPort', payload).catch(e => {
        // //console.log(e);
        this.showError(e);
      });
    },
    async createNewServer() {
      this.runServerCreate = true;
      await this.createServer()
        .then(async () => {
          // //console.log(this.formData.portId, '!!!!!', this.port_id, '------', this.formData.portId);
          const server = {
            name: this.name,
            adminPass: this.root,
            user_data: this.generateRootPassword,
            networks: [
              {
                port: this.formData.portId === 'Новый порт' ? this.port_id : this.formData.portId,
              },
            ],
            flavorRef: this.conf === '1' ? this.baseFlavor.id : this.currentFlavorId,
            imageRef: this.flavor === '1' || this.flavor === '2' ? this.image.id : '',
            block_device_mapping_v2: [],
          };

          if (this.sshKey && this.sshKey.length > 0) server.key_name = this.sshKey;
          if (this.localDisk) server.config_drive = true;
          if (this.localDisk) {
            server.block_device_mapping_v2.push({
              source_type:
                this.flavor === '4' ? 'snapshot' : this.flavor === '3' ? 'volume' : 'image',
              uuid: this.image.id,
              destination_type: 'local',
              boot_index: 0,
            });
          }
          if (this.localDisk && this.isNetworkDisk)
            server.block_device_mapping_v2.push({
              source_type: 'blank',
              volume_size: this.networkDiskConfigurator.config.value,
              volume_type: this.type.name,
              destination_type: 'volume',
              delete_on_termination: this.isDeleteNetworkDisk[0].config.value === 'on',
              boot_index: -1,
            });
          if (!this.localDisk && this.isNetworkDisk) {
            const addDisk = {
              source_type: ['3', '4'].includes(this.flavor)
                ? 'blank'
                : this.flavor === '4'
                ? 'snapshot'
                : this.flavor === '3'
                ? 'volume'
                : 'image',
              volume_size: this.networkDiskConfigurator.config.value,
              volume_type: this.type.name,
              destination_type: 'volume',
              delete_on_termination: this.isDeleteNetworkDisk[0].config.value === 'on',
              boot_index: ['3', '4'].includes(this.flavor) ? -1 : 0,
            };
            if (['1', '2'].includes(this.flavor)) addDisk.uuid = this.image.id;
            server.block_device_mapping_v2.push(addDisk);
          }
          if (!this.localDisk && ['3', '4'].includes(this.flavor))
            server.block_device_mapping_v2.push({
              uuid: this.image.id,
              source_type:
                this.flavor === '4' ? 'snapshot' : this.flavor === '3' ? 'volume' : 'image',
              destination_type: 'volume',
              boot_index: 0,
            });
          // //console.log(server);
          return server;
        })
        .then(async data => {
          const server = await this.$store.dispatch('moduleStack/createServer', data);
          await this.$router.push({
            name: 'Server',
            params: { serverid: server.server.id },
          });
        })
        .then(async () => {})
        .catch(err => {
          // //console.log(this.port_id);
          if (this.port_id) {
            this.deleteCurrentPort(this.port_id).then();
            this.port_id = '';
          }
          //console.log(err);
          if (err && err.response.data.forbidden) {
            this.showError(err.response.data.forbidden.message);
          } else {
            this.showError(err.response.data.badRequest.message);
          }
        })
        .finally(() => {
          this.runServerCreate = false;
        });
    },
    updateConfig(data) {
      this.configData = { ...data };
      this.calcSum();
    },
    portRouters() {
      this.$store.state.moduleStack.ports
        .filter(x => x.device_owner === 'network:router_interface')
        .forEach(x => {
          this.networkWithSubnet.push({ net: x.network_id, subnet: x.fixed_ips[0].subnet_id });
        });
      return this.networkWithSubnet;
    },
    resetConfig() {
      this.configData = null;
      this.costDetails = null;
    },
    getMask(ip) {
      return ip.slice(0, ip.lastIndexOf('.') + 1);
    },
    getMaskNewNetwork(ip) {
      this.maskNewNetwork = ip.slice(0, ip.lastIndexOf('.') + 1);
      return this.maskNewNetwork;
    },
    isCorrectIp(ip) {
      // //console.log(ip);
      if (this.newIpAddress.includes(ip)) {
        // //console.log(this.newIpAddress.includes(ip));
        this.formData.port.fixed_ips[0].ip_address = ip;
        this.value = ip;
        this.$emit('ready', {
          port: this.formData.port,
        });
      } else return this.$emit('notready');
    },
    getLastIpSectionNewNetwork(ip) {
      this.lastIpSection = +ip.split('/').at(0).split('.').at(-1);
      this.subnetAddr = +ip.split('/').at(-1);
      return this.lastIpSection + 1;
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      // this.calcSum();
    },
    onCheck(name, val) {
      // //console.log(name, val);
      this.formData.dhcp.value = val;
      this.formData.subnet.dhcp = val;
    },

    onChangeNewNetwork(name, event) {
      this.formData.subnet = {
        name: this.subnetNetwork,
        cidr: this.subnetNetwork,
        network_id: !this.newNetName ? this.newNetworkName : '',
        dhcp: this.formData.dhcp.value,
        dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
        gateway: this.computeGateway,
      };
      // //console.log(this.formData.subnet.network_id);
      // //console.log('subnet', subnet, this.gateway, this.computeGateway);
      if (this[name] === 'subnetNetwork') {
        // //console.log('subnet');
      }
      this[name] = event;
      if (this.network && this.subnetNetwork && this.computeGateway) {
        return this.$emit('change', {
          net: this.network,
          subnet: this.subnetNetwork,
          gateway: this.computeGateway,
          dhcp: this.formData.dhcp.value,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
          // enabled: this.formData.checkbox.value,
        });
      } else return this.$emit('notready');
    },
    onChangeSshKey(event) {
      // //console.log(event);
    },
    onChangeSlider(event) {
      // //console.log(event);
      this.$emit('ready');
      if (event.name === 'networkDisk') {
        this.costNetworkDisk = event.cost * event.config.value;
        this.configToCost.network.count = event.config.value;
        this.period = this.period === 24 ? 1 : 24; // TODO fix whatch
        this.backupNetworkDisk.networkDisk = this.networkDiskConfigurator.config.value;
        this.period = this.period === 1 ? 24 : 1;
        this.$emit('create', this.backupNetworkDisk.networkDisk);
      } else {
        const params = {
          cpu: this.flavorOther.find(x => x.name === 'cpu').config.value,
          ram: this.flavorOther.find(x => x.name === 'ram').config.value,
        };
        this.flavorOther.find(x => x.name === 'disk')
          ? (params.disk = this.flavorOther.find(x => x.name === 'disk').config.value)
          : null;
        this.$emit('create', params);
        this.backupFormData = Object.assign({}, params);
        // //console.log(this.backupFormData);
        // //console.log(value);
        // //console.log(value.config.value, value.name);
      }
    },
    onChange(event) {
      // //console.log(event);
    },
    onChangeNewNetworkName() {
      this.formData.subnet.network_id = '';
      // //console.log(event);
    },
    onChangeNetwork(event) {
      if (this.network === '1' && this.subnet.includes(event)) {
        // //console.log('event: network=1', event);
        this.formData.port.network_id = this.$store.state.moduleStack.networks.find(x =>
          x.subnets.find(i => i === event)
        ).id;
        this.formData.portId = '';
        // //console.log('network', this.formData.port);
      } else if (this.network === '1' && this.allFreePortsId.includes(event)) {
        this.formData.portId = event;
        this.value = '';
        // //console.log('event', event);
      } else if (this.network === '2') {
        // //console.log('NewSubnet:2', event);
        this.formData.subnet = {
          name: this.subnetNetwork,
          cidr: this.subnetNetwork,
          network_id: event,
          dhcp: this.formData.dhcp.value,
          dnsNames: this.dnsNames ? this.dnsNames.split('\n') : this.defaultDnsNames.split('\n'),
          gateway: this.gateway ? this.gateway : this.computeGateway,
        };
        // //console.log('subnet', this.formData.subnet);
      } else if (this.network === '4' && this.subnet.includes(event)) {
        // //console.log('event: network=4', event);
        this.formData.port.network_id = this.$store.state.moduleStack.networks.find(x =>
          x.subnets.find(i => i === event)
        ).id;
        // //console.log('float', this.formData.port);
      }
      if (this.subnet.includes(event)) {
        // //console.log(event);
        this.networkId = event;
        const port = this.$store.state.moduleStack.ports;
        const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event));
        const formatPort = updatePort.reduce((acc, item) => {
          if (item?.length) acc.push(item);
          return acc;
        }, []);
        this.listIp = formatPort
          .filter(x => x[0])
          .flat()
          // .map(x => x[0].ip_address)
          .map(x => x.ip_address)
          .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
      } else {
        Object.assign(this.formData, event);
        // //console.log(event); //в этом моменте надо делать проверку адреса введенного, который является event
        this.isCorrectIp(event);
        if (
          this.formData.port.fixed_ips[0].subnet_id &&
          this.formData.port.fixed_ips[0].ip_address &&
          this.formData.port.network_id
        ) {
          return this.$emit('change', {
            port: this.formData.port,
          });
        } else return this.$emit('notready');
      }
    },
    showSuccessModal(text) {
      this.$modals.open({
        name: 'SuccessOrder',
        size: 'small',
        text,
      });
    },
  },
};
</script>
// "networkDiskError": "Вы достигли квоты по использованию сетевых дисков, обратитесь в поддержку
для изменения квоты", // "floatingIpError": "Вы достигли квоты по использованию плавающих адресов,
обратитесь в поддержку для изменения квоты", // "privateNetworkError": "Вы достигли квоты по
использованию приватных сетей, обратитесь в поддержку для изменения квоты", // "privateSubnetError":
"Вы достигли квоты по использованию приватных подсетей, обратитесь в поддержку для изменения квоты",
<i18n>
{
  "ru": {
    "title": "Создание сервера",
    "source": "Источник",
    "networkPrivate": "Все адреса в подсети доступны из интернета. \nОдин адрес из нее будет прописан на сетевом интерфейсе виртуальной машины.",
    "network": "Сеть",
    "server": {
      "admin": "Пароль для \"Administrator\"",
      "gateway": "Шлюз",
      "flavor": "Выбор конфигурации",
      "ip": "IP-адрес",
      "portAviable": "Выбрать порт",
      "label": "Имя сервера",
      "net": "Новая сеть",
      "hint": "Имя сервера может содержать от 1 до 63 символов",
      "networkChange": "Выберите сеть",
      "network": "Сеть",
      "networkDisk": "Сетевой диск",
      "root": "Доступ",
      "ssh":"SSH-ключ",
      "sshtext":"Чтобы безопасно и быстро подключаться к серверу, \nвыберите ключ или загрузите новый.",
      "subnet": "Подсеть",
      "password": "Пароль для \"root\"",
      "port": "IP-адрес",
      "typeOfDisk": "Тип диска"
    },
    "new_key": "+Новый SSH-ключ",
    "warnings": "Обращаем ваше внимание на то, что расходы формируются раз в сутки, а счёт выставляется за фактически отработанное время. Сами расчёты осуществляются каждые 5 минут. \nПредставленные ниже цены рассчитаны исходя из того, что сервер проработает полный период: час, день, месяц (30 дней).",
    "nav": {
      "image" : "Образы",
      "other" : "Свой образ",
      "disk" : "Диск",
      "snap" : "Снапшот",
      "base": "Стандартные конфигурации",
      "network": "Выбрать существующую сеть",
      "newNetwork": "Новая приватная подсеть",
      "newPublicNetwork": "Новая внешняя подсеть",
      "publicNetwork": "Внешняя подсеть",
      "newPublicIp": "Новый публичный IP-адрес",
      "free": "Произвольная конфигурация"
    },
    "label": {
      "image": "Публичный образ",
      "error": "Свободные порты закончились!"

    },
    "sure": {
      "confirm": "Добавить",
      "key": "SSH-ключ успешно добавлен",
      "close": "Закрыть"
    },
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "choise": "Сервер",
    "additional": "Дополнительно",
    "summary": "Итого",
    "payfor": "при оплате за",
    "pay": "Создать",
    "summaryLabels": {
      "network": "Сетевой диск, ГБ",
      "local": "Локальный диск, ГБ",
      "ram": "Оперативная память, ГБ",
      "cpu": "Процессор, шт",
      "float": "Плавающий IP, шт",
      "public": "Публичная сеть, шт"
    },
    "upSize": "Диски с разметкой MBR не могут быть больше 2ТБ.",
    "upSize2": " Диски больше 2ТБ должны быть размечены GPT.",
    "order": "В корзину",
    "specs": {
      "disc": "{n} ГБ SSD",
      "mem": "{n} ГБ RAM",
      "ncpu": "{n} CPU"
    },
    "dns": {
      "restore": "Вернуть DNS по умолчанию",
      "placeholder": "DNS адреса"
    },
    "success": {
      "basket": "Заказ на {num} успешно добавлен в корзину",
      "pay": "Публичное облако сформировано, {num} списано с лицевого счета",
      "redirect": "Заявка на подключение публичного облака успешно сформирована, продолжите оплату и дождитесь обновления данных"
    },
    "needbalance": "Необходимо пополнить баланс"
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.quotas{
  text-align: center;
  padding-bottom: 0;
}
.page {
  margin-top: 1rem
}
.section-base {
  margin-bottom: 2.5rem;
}
  .section-header {
    margin-top: 1rem;
  }
.resize-flavor {

  &__config {
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
      text-align: center;

    &-item {
      min-width: 6rem;
      +breakpoint(sm-and-up) {
        min-width: 10rem;

      }
    }
  }
}
.dns {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.stack-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
.password {
    display: flex;

    &__field {
      margin-left: 0.75rem;
    }
  }
.order-summary {
  &__list {
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    & + & {
      //margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 33%;
      margin-right: 1.5rem;
    }
  }
  &__value {
    +breakpoint(sm-and-up) {
      flex: 0 0 19%;
      margin-right: 1.5rem;
    }
  }
  &__price {
    +breakpoint(sm-and-up) {
      flex: 0 0 19%;
      margin-right: 1.5rem;
    }
  }
}
</style>
